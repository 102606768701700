<template>
    <div>
        <div
            class="flex justify-between px-4 py-5 m-auto max-w-screen-2xl sm:px-12 lg:px-24"
        >
            <a href="/">
                <img
                    src="/themes/mbs/assets/images/logo-sauce.svg"
                    alt="MBS Logo"
                    class="w-24"
                />
            </a>
            <a
                @click="toggleMenu"
                href="#"
                id="main-menu-button"
                class="flex-shrink-0 text-black"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="currentColor"
                    class="w-11 h-11"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                </svg>
            </a>
        </div>
        <div
            v-if="showMenu"
            @click.self="toggleMenu"
            id="main-menu-dark"
            class="fixed top-0 z-50 w-full h-full bg-black cursor-pointer bg-opacity-60"
        >
            <div
                v-html="menu"
                id="main-menu"
                class="fixed top-0 right-0 flex flex-col w-full h-full gap-5 p-10 pt-20 pr-16 overflow-auto text-5xl font-bold text-white cursor-default sm:pr-24 lg:pr-32 sm:w-auto sm:p-16 lg:p-20 sm:text-6xl md:text-7xl lg:text-8xl bg-mbs-red"
            ></div>
            <a
                @click="toggleMenu"
                id="main-menu-close"
                class="absolute right-0 flex items-center justify-center w-10 h-10 mr-4 text-3xl no-underline bg-white rounded-full cursor-pointer sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 sm:text-4xl md:text-5xl lg:text-6xl text-mbs-red top-4"
            >
                <i class="fa-solid fa-xmark"></i>
            </a>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

defineProps(["menu"]);

const showMenu = ref(false);

const toggleMenu = () => (showMenu.value = !showMenu.value);
</script>

<style scoped>
#main-menu-dark {
    animation: fade-in 0.15s ease-in-out forwards;
}
@keyframes fade-in {
    0% {
        filter: opacity(0);
    }
    100% {
        filter: opacity(1);
    }
}
#main-menu,
#main-menu-close {
    animation: slide-in 0.15s ease-in-out forwards;
}
@keyframes slide-in {
    0% {
        right: -5rem;
        filter: brightness(0) opacity(0.5);
    }
    100% {
        right: 0;
        filter: brightness(1) opacity(1);
    }
}
</style>
