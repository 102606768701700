var elems = document.querySelectorAll(".accordion-button")

elems.forEach(function(elem) {
    elem.addEventListener('click', function(event) {
        accordionElementClick(event)
    })
})

function accordionElementClick(event) {

    const accordion = event.target.closest(".accordion")
    const accordionAllEntries = accordion.querySelectorAll(".accordion-entry")
    const accordionActiveEntry = event.target.closest(".accordion-entry")

    accordionAllEntries.forEach(function(curr){
        if (curr === accordionActiveEntry) {
            accordionActiveEntry?.querySelector(".accordion-content").classList.toggle("max-h-0")
            accordionActiveEntry?.querySelector(".accordion-arrow").classList.toggle("rotate-180")
        } else {
            curr?.querySelector(".accordion-content").classList.add("max-h-0")
            curr?.querySelector(".accordion-arrow").classList.remove("rotate-180")
        }
    })

}